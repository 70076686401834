import ProjectCard from "./ProjectCard/ProjectCard";
import AnalystHiveLogo from "../../../assets/icons/ah_logo.svg";
import BBallLogo from "../../../assets/images/bballytics.png";
import SielosValymasLogo from "../../../assets/icons/sv_logo.svg";

import "./Projects.scss";

const Projects = () => {
  return (
    <>
      <div className="cards">
        <ProjectCard title="AnalystHive" logoSVGSrc={AnalystHiveLogo} url="https://analysthive.com">
          AnalystHive is a platform that has a combination of tools for financial productivity. Implemented with a large
          variety of <b>cloud tools and services</b> it allows PDF document analysis at <b>large scale</b> with
          microscopic costs.
        </ProjectCard>
        <ProjectCard title="Sielos Valymas" logoSVGSrc={SielosValymasLogo} url="https://sielosvalymas.lt">
          SielosValymas - an e-shop project. It involves leveraging various technologies to manage the shop efficiently.
          Including a robust content management system <b>(CMS)</b>, seamless <b>Paysera payment</b> integration, and
          automated <b>PDF document creation</b> and <b>email sending</b> functionalities.
        </ProjectCard>
        <ProjectCard title="BBallytics" logoPNGSrc={BBallLogo} url="https://bballytics.com">
          BBallytics - European Basketball analytics platform. It involves <b>data scraping</b> tools,
          <b> user management</b> CMS system and <b>Paypal payment</b> integration.
        </ProjectCard>
      </div>
    </>
  );
};

export default Projects;
