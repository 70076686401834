import { AxiosResponse, AxiosError } from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { DEFAULT_REQUEST_ERROR } from "utils";
import { Api } from "./api";

export function useApiCall<T = any>() {
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const makeRequest = (endpoint: string, queryParams: {} = {}, body: {} = {}, method: string = Api.METHODS.GET) => {
    setLoading(true);
    Api.request(endpoint, queryParams, body, method)
      .then((res) => {
        setResponse(res);
      })
      .catch((axiosError: AxiosError) => {
        setError(axiosError.message);
        toast.error(DEFAULT_REQUEST_ERROR, { id: new Date().toString() });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { makeRequest, loading, response, error };
}
