import "./About.scss";

const About = () => {
  return (
    <>
      <h3>
        I'm a <b>Senior Software Engineer</b> from Lithuania, Vilnius.{" "}
      </h3>
      <p>
        10+ years background in creating and executing innovative software solutions to enhance business productivity.
      </p>
      <p>
        Committed to delivering custom solutions that optimize business processes and enhance user experiences.
        Experienced in web and desktop application development, I offer a solid track record of creating efficient and
        innovative solutions for diverse clients.
      </p>
    </>
  );
};

export default About;
