import { Profile, Resume, Top } from "components";
import "./Home.scss";

const Home = () => {
  return (
    <>
      <Top />
      <div className="main">
        <Profile />
        <Resume />
      </div>
    </>
  );
};

export default Home;
