import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./Profile.scss";
import avatar from "assets/images/avatar.jpg";

const Profile = () => {
  return (
    <div className="profile">
      <img className="avatar-img" src={avatar} alt="Dominykas Stumbras" />
      <h1>Dominykas Stumbras</h1>
      <h2>Software Engineer</h2>
      <ul className="social-icons">
        <li>
          <a href="https://www.linkedin.com/in/domchix/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={icon({ name: "linkedin", style: "brands" })} className="social-icon" />
          </a>
        </li>
        <li>
          <a href="https://github.com/domchix" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={icon({ name: "github", style: "brands" })} className="social-icon" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Profile;
