import svgProcess from "../../../assets/icons/process.svg";
import svgDatabase from "../../../assets/icons/database.svg";
import svgWeb from "../../../assets/icons/web.svg";
import svgWindows from "../../../assets/icons/windows.svg";

import { ReactSVG } from "react-svg";
import "./Services.scss";

const Services = () => {
  return (
    <div className="services">
      <div className="service">
        <ReactSVG className="service-logo" src={svgProcess} />
        <h3>Business Process Automation</h3>
        <p>
          Identifying manual processes that can be automated and developing software solutions to streamline and
          optimize business workflows.
        </p>
      </div>
      <div className="service">
        <ReactSVG className="service-logo" src={svgWeb} />
        <h3>Web Application Development</h3>
        <p>
          Creating custom web applications tailored to the client's specific needs, using technologies like React,
          Django or ASP.NET.
        </p>
      </div>
      <div className="service">
        <ReactSVG className="service-logo" src={svgDatabase} />
        <h3>Database Design and Optimization</h3>
        <p>Designing efficient and scalable databases, optimizing database performance, and ensuring data integrity.</p>
      </div>
      <div className="service">
        <ReactSVG className="service-logo" src={svgWindows} />
        <h3>Windows Application Development</h3>
        <p>
          Creating Windows desktop applications and Office add-ins (Word, Excel, PowerPoint) to boost productivity and
          automate tasks.
        </p>
      </div>
    </div>
  );
};

export default Services;
