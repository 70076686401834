import { ReactSVG } from "react-svg";

import "./ProjectCard.scss";

type ProjectCardProps = {
  title: string;
  logoSVGSrc?: string;
  logoPNGSrc?: string;
  url: string;
  children: any;
};

const ProjectCard = (props: ProjectCardProps) => {
  return (
    <div className="project-card">
      <a href={props.url} className="project-card-wrapper">
        {props.logoSVGSrc ? (
          <ReactSVG className="project-card-logo" src={props.logoSVGSrc} />
        ) : (
          <div className="project-card-logo">
            <img className="project-card-logo-png" src={props.logoPNGSrc} alt={props.title} />
          </div>
        )}
        <h2 className="project-card-title">{props.title}</h2>
        <p className="project-card-description">{props.children}</p>
      </a>
    </div>
  );
};

export default ProjectCard;
