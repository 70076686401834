// Holds all available endpoint list
export const config = {
  API_BASE_URL_DEV: "http://127.0.0.1:8000/",
  API_BASE_URL_PROD: "https://api.dominykasstumbras.com/",
  API_ENDPOINTS: {
    CONTACT: "api/inquiries/",
  },
  APP_PATHS: {
    MAIN: "/",
  },
};
