import { config } from "api";
import { Home } from "pages";
import { Navigate, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Routes>
      <Route index path={config.APP_PATHS.MAIN} element={<Home />} />

      <Route path="*" element={<Navigate to={config.APP_PATHS.MAIN} />} />
    </Routes>
  );
};

export default App;
