import "./Slider.scss";

interface SliderProps {}

const Slider = (props: React.PropsWithChildren<SliderProps>) => {
  return (
    <div className="slider">
      <div className="slide">{props.children}</div>
      <div className="slide">{props.children}</div>
    </div>
  );
};

export default Slider;
