import pngStackshare from "../../assets/images/stackshare.png";

import svgAws from "../../assets/icons/aws.svg";
import svgDjango from "../../assets/icons/django.svg";
import svgAzure from "../../assets/icons/azure.svg";
import svgDotnet from "../../assets/icons/dotnet.svg";
import svgReact from "../../assets/icons/react.svg";

import pngBBallytics from "../../assets/images/bballytics.png";
import pngReorg from "../../assets/images/reorg.png";
import pngCompensa from "../../assets/images/compensa.png";
import pngEmokymai from "../../assets/images/emokymai.png";

import About from "./About/About";
import Contacts from "./Contacts/Contacts";
import Projects from "./Projects/Projects";
import Services from "./Services/Services";
import Slider from "./Slider/Slider";
import WorkExperience from "./WorkExperience/WorkExperience";

import "./Resume.scss";

const Resume = () => {
  return (
    <div className="resume">
      <h2 className="about">About Me</h2>
      <About />
      <h2 className="about">Technologies</h2>
      <Slider>
        <img src={svgAws} alt="AWS logo" className="tech-logo" />
        <img src={svgAzure} alt="Azure logo" className="tech-logo" />
        <img src={svgDjango} alt="Django logo" className="tech-logo" />
        <img src={svgDotnet} alt=".NET logo" className="tech-logo" />
        <img src={svgReact} alt="React logo" className="tech-logo" />
      </Slider>
      <a
        className="stackshare"
        href="https://stackshare.io/dominykasstumbras/portfolio"
        target="_blank"
        rel="noreferrer"
      >
        Find out more:
        <img src={pngStackshare} alt="stackshare" />
      </a>
      <h2 className="about">Projects</h2>
      <Projects />
      <h2 className="about">Work Experience</h2>
      <WorkExperience />
      <h2 className="about">Services</h2>
      <Services />
      <h2 className="about">Clients</h2>
      <Slider>
        <a href="https://bballytics.com" target="_blank" rel="noreferrer" className="link-logo">
          <img src={pngBBallytics} alt="BBallytics logo" className="client-logo" />
        </a>
        <a href="https://reorg.com/" target="_blank" rel="noreferrer" className="link-logo">
          <img src={pngReorg} alt="Reorg logo" className="client-logo" />
        </a>
        <a href="https://www.compensa.lt/" target="_blank" rel="noreferrer" className="link-logo">
          <img src={pngCompensa} alt="Compensa logo" className="client-logo" />
        </a>
        <a href="https://emokymusprendimai.lt/" target="_blank" rel="noreferrer" className="link-logo">
          <img src={pngEmokymai} alt="Emokymai logo" className="client-logo" />
        </a>
      </Slider>
      <h2 className="about">Get In Touch</h2>
      <Contacts />
    </div>
  );
};

export default Resume;
