import axios from "axios";
import { config } from "./config";

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? config.API_BASE_URL_PROD : config.API_BASE_URL_DEV;

export class Api {
  static ENDPOINTS = config.API_ENDPOINTS;
  static METHODS = {
    GET: "get",
    POST: "post",
    PATCH: "patch",
  };

  static async request(endpoint: string, queryParams: {}, body: {}, method: string, authorize = true) {
    return axios.request({
      url: endpoint,
      method: method,
      params: queryParams,
      headers: {},
      data: body,
    });
  }
}
