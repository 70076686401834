import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "./ContactForm/ContactForm";
import "./Contacts.scss";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useState } from "react";

const Contacts = () => {
  const [linkCopied, setLinkCopied] = useState(false);

  return (
    <div className="contacts">
      <div className="contacts-form">
        <ContactForm />
      </div>
      <div className="contacts-data">
        {
          linkCopied && <span className="clipboard-msg">
            Email copied!
          </span>
        }
        <div className="contact copy-clipboard" onClick={() => { navigator.clipboard.writeText("Dominykas@StumbrasTech.com"); setLinkCopied(true); }}>
          <div className="contact-icon">
            <FontAwesomeIcon icon={icon({ name: "envelope" })} className="contacts-icon" />
          </div>
          <div className="contact-details">
            <p className="email">Dominykas@StumbrasTech.com</p>
          </div>
        </div>
        <a href="https://www.linkedin.com/in/domchix/" target="_blank" rel="noreferrer">
          <div className="contact">
            <div className="contact-icon">
              <FontAwesomeIcon icon={icon({ name: "linkedin", style: "brands" })} className="contacts-icon" />
            </div>
            <div className="contact-details">
              <p>LinkedIn</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Contacts;
