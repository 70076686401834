import { ReactSVG } from "react-svg";
import "./Top.scss";
import logoSVG from "assets/icons/logo.svg";

const Top = () => {
  return (
    <>
      <div className="Top">
        <ReactSVG className="svg-logo" src={logoSVG} />
        <h1>Dominykas Stumbras</h1>
        <h2>Software Engineer</h2>
      </div>
    </>
  );
};

export default Top;
